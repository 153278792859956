<template>
<v-app>
  <v-container>

    <v-row class="mt-10 mb-5">
      <v-col>
        <h1 class="text-left mb-5">
          Contribute data
        </h1>
        <p class="text-left text-justify">
          Interested PIs can contact us for an informal discussion about the eligibility of their data.
          Inclusion criteria are:
        </p>
        <p class="text-left text-justify">
          1. Use an acute stress paradigm in humans in a laboratory-setting (e.g. Trier Social Stress Test, Cold Pressor Task.).
        </p>
        <p class="text-left text-justify">
          2. Sign the Consortium Agreement (please <a href="mailto:stresseu@amsterdamumc.nl">e-mail us</a> for details).
        </p>
        <p class="text-left text-justify">
          3. Provide data in two files (see <b>Upload data</b> below):
        </p>
        <p class="text-left text-justify pl-6 mb-0">
          a) individual pseudonymized participant data of a selection of variables (i.a. gender, age, contraceptive use (where applicable), clinical diagnosis (where applicable), cortisol concentrations), and
        </p>
        <p class="text-left text-justify pl-6 pt-2">
          b) meta-information for each study on available data (e.g. questionnaires, cognition, structured interviews, biological outcomes, neuroimaging, and EEG).
        </p>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row class="mt-9">
      <v-col>
        <h3 class="text-left">
          Upload data
        </h3>
        <p class="text-left text-justify">
          If you want to contribute to this initiative, you can upload your own data.
          This data will then be added to the database and directly available for queries in the data tab.
          The steps to upload data are described below.
        </p>
      </v-col>
    </v-row>

    <v-row class="">
      <v-col class="text-left text-justify">
        <p class="">
          1) Download the two templates below: 'study.xlsx' and 'participants.xlsx'
        </p>
        <v-btn class="" :loading="loading" :disabled="loading" color="primary" @click="downloadTemplates">
          Download Templates
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <p class="text-left mb-0 text-justify">
          2) Fill in both templates; in the templates you'll find a description and examples on how to fill in both excel files
        </p>
      </v-col>
    </v-row>

    <v-row class="">
      <v-col class="text-left text-justify">
        <p class="">
          3) After you have filled in the templates, press "send data". This wil open up your email client with the appropriate email-address to send your templates to. Attach your templates to the email, and send. After checking the data and verifying with you, the files will be incorporated in our database system.
        </p>
        <v-btn class="" :loading="loading" :disabled="loading" color="primary" @click="mailData">
          Send Data
        </v-btn>
      </v-col>
    </v-row>

  </v-container>
</v-app>
</template>

<script>
export default {
  name: "Admin",
  data() {
    return {

    }
  },
  computed: {

  },
  methods: {
    downloadTemplates() {
      window.open('templates.zip')
    },
    mailData() {
      window.location.href = "mailto:stresseu@amsterdamumc.nl?subject=New data for Stress-EU database";
    },

  },
}
</script>
